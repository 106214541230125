import * as React from "react";
import UpNext from "../components/UpNext";

const NotFoundPage = () => (
  <React.Fragment>
    <section className="section section-404">
      <div className="container">
        <div className="page-title">
          <h6 className="has-text-centered">404</h6>
          <h1 className="title has-text-centered">Page Not Found</h1>
        </div>
        <div className="page-content has-text-centered">
          <p>Sorry the page you were looking for could not be found. In the meantime, why not play with this fun background animation?</p>
          <UpNext url="/" text="Back to Home"/>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default NotFoundPage;
